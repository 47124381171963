import * as React from "react"
import Main from "../components/atoms/Main/Main";

import {StaticImage} from "gatsby-plugin-image";
import FooterNav from "../components/molecules/FooterNav/FooterNav";
import Button from "../components/atoms/Button/Button";

// markup
const IndexPage = () => {
  return (
    <Main footerContent={(
      <FooterNav>
        <Button to="/register">
          Profil erstellen
        </Button>
      </FooterNav>
    )}>
      <StaticImage src="../images/home/start.png" alt="" layout="fullWidth"/>

    </Main>
  )
}

export default IndexPage
